// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
var targetIdHold = jQuery(this.hash);

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t = t / d - 1) * t * t * t - 1) + b;
};

jQuery(document).ready(function () {
  jQuery('a[href*="#"]').click(function () {
    var frontScene02Top = $("#scene02").offset().top; //KVスクロール連動切り替えポイント
    var targetId = jQuery(this.hash);
    let scroll = $(window).scrollTop();

    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) + ']');
      var headerH = $('#header').innerHeight();
      var adjust = 0; // ターゲット先へスクロール移動

      if (target.length) {
        var targetOffset = target.offset().top - headerH - adjust;

        if ( scroll <= frontScene02Top ) {  //ブラウザ上端がscene02より上にある時、移動先の位置をシーン02がrelativeになった時の高さの位置に変更する
          var scene02IH = $("#scene02").innerHeight();
          var heroScene02IH = $(".js-heroScene02").innerHeight();
          var scene02Pb = Number($("#scene02").css('padding-bottom').replace('px', ''));
          var scene02ContentPt = Number($("#scene02_content_dummy").css('padding-top').replace('px', ''));  //

          targetOffset = targetOffset - scene02IH + heroScene02IH + scene02Pb + scene02ContentPt;

        } else {  //ブラウザ上端がscene02より下にある時は、シーン02の高さは変わらないので、そのまま移動する
          var targetOffset = target.offset().top - headerH - adjust;
        }

        jQuery('html,body').animate({
          scrollTop: targetOffset
        }, 1200, 'swing');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {} else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });
});


// 他ページからのsmoothScroll
$(function () {
  //現在のページURLのハッシュ部分を取得
  var hash = location.hash; //ハッシュ部分がある場合の条件分岐

  if (hash) {
    var headerH = $('#header').innerHeight();
    $("html, body").stop().scrollTop(0); //処理を遅らせる

    setTimeout(function () {
      var target = $(hash);
      var position = target.offset().top - headerH;

      //他ページからsmoothScrollする場合、必ずブラウザ上端がscene02より上にあるため、移動先の位置をシーン02がrelativeになった時の高さの位置に変更する
      var scene02IH = $("#scene02").innerHeight();
      var heroScene02IH = $(".js-heroScene02").innerHeight();
      var scene02Pb = Number($("#scene02").css('padding-bottom').replace('px', ''));
      var scene02ContentPt = Number($("#scene02_content_dummy").css('padding-top').replace('px', ''));  //

      position = position - scene02IH + heroScene02IH + scene02Pb + scene02ContentPt;

      $("html, body").animate({
        scrollTop: position
      }, 1200, "swing");
    }, "1");
  }
});


// slide btn
$(window).on('load', function() {
  $(".js-slideBtn").addClass('is-active');
  $(".js-slidePanel").addClass('is-active');
  $(".js-slidePanel").css('display','block');
});

$(window).on('resize', function() {
  let winIW = window.innerWidth;

  if ( winIW < 600 ) {
  } else {
    $(".js-slideBtn").removeClass('no-active');
    $(".js-slideBtn").addClass('is-active');

    $(".js-slidePanel").removeClass('no-active');
    $(".js-slidePanel").addClass('is-active');
    $(".js-slidePanel").css('display','block');
  }
});
/*
$(window).on('load resize', function() {
  let winIW = window.innerWidth;

  if ( winIW < 600 ) {
    $(".js-slideBtn").addClass('no-active');
    $(".js-slidePanel").addClass('no-active');
  } else {
    $(".js-slideBtn").removeClass('no-active');
    $(".js-slideBtn").addClass('is-active');
    $(".js-slideBtn").css('display','');

    $(".js-slidePanel").next(".js-slidePanel").slideDown(300);

    $(".js-slidePanel").removeClass('no-active');
    $(".js-slidePanel").addClass('is-active');
    $(".js-slidePanel").css('display','');
  }
});
*/

$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      if ( $(this).hasClass('is-active') ) {
        $(this).removeClass('is-active');
        $(this).addClass('no-active');

        $(element).next(".js-slidePanel").slideUp(300);

        $(element).next(".js-slidePanel").removeClass('is-active');
        $(element).next(".js-slidePanel").addClass('no-active');

      } else {
        $(this).removeClass('no-active');
        $(this).addClass('is-active');

        $(element).next(".js-slidePanel").slideDown(300);

        $(element).next(".js-slidePanel").removeClass('no-active');
        $(element).next(".js-slidePanel").addClass('is-active');
      }
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// scroll
$(window).on('load scroll resize', function(){
  let winW = $(window).width();
  let winIW = window.innerWidth;
  let winH = $(window).height();
  let winIH = window.innerHeight;
  let headerH = $('#header').innerHeight();

  let hd = $('#header');
  let hero = $('#hero');
  let scroll = $(window).scrollTop();

  if ( $("#wrap").hasClass("-front") ) {
    let frontRemoveFixedTop = $('#scene02').offset().top;
  
    //KV要素
    let heroScene01 = $(".js-heroScene01");
    let heroScene01Logo = $(".js-heroScene01Logo");
  
    if ( scroll < (winIH * 0.2) ) {
      heroScene01Logo.removeClass('e-fadeOut1');
      heroScene01Logo.addClass('e-fadeIn1');
    } else {
      heroScene01Logo.removeClass('e-fadeIn1');
      heroScene01Logo.addClass('e-fadeOut1');
    }
    
    /* KVシーン02〜03 */
    /* KV〜シーン02への切り替え部分のプログラムは、luxy.jsに記述。app.jsに記述すると、一気にスクロールした時に、処理がうまくいかず、切り替えられないことがあるため */
    /* luxy.jsの「2024/03/05 add start」〜「2024/03/05 add end」に記述 */
  }
});


// バーガーメニュー開閉
$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
    } else {
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
    }
  });
});

$(window).on('resize', function(){
  let winIW = window.innerWidth;

  //PC時は、ドロワーはis-open、is-closeクラスを削除する
  if ( winIW >= 800 ) {
    $("#js-drawerOpen").removeClass('is-open');
    $("#drawer").removeClass('is-open');
    $("#gnav").removeClass('is-open');
    $("#js-drawerOpen").removeClass('is-close');
    $("#drawer").removeClass('is-close');
    $("#gnav").removeClass('is-close');
  }
});

//メニュークリック
$(function(){
  $(".js-gnavItem").each(function(index,element) {
    $(element).click(function() {
      if ( $("#js-drawerOpen").hasClass('is-open') ) {
        $("#js-drawerOpen").removeClass('is-open');
        $("#drawer").removeClass('is-open');
        $("#gnav").removeClass('is-open');
      }
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});


//指定classのついたタグ内の文字を、1文字ずつspanで囲む
$(document).ready(function () {
  // 文字列へのspan設定
  $(".js-justification").each(function() {
    var content = $(this).html();
    var trimText = $.trim(content);
    var newText = "";

    trimText.split("").forEach(function(e) {
      if(e == ' '){
        // 空白対策
        newText += '<span> </span>';
      } else {
        newText += '<span>' + e + '</span>';
      }
    });
    $(this).html(newText);
  });
});


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}
